<template>
  <div v-if="currentTab !== 'closed'">
    <img src="../assets/img/settings.svg" class="desktop-only drawer-icon" @click="toggleDrawer">
    <img src="../assets/img/settings.svg" class="mobile-only drawer-icon" style="cursor:pointer" @click="toggleDrawer">
    <div class="drawer-wrapper" :class="(showDrawer) ? 'showDrawer' : ''">
      <div class="drawerUnderlay" @click="showDrawer = false" />
      <div class="fieldslist-drawer side-drawer">
        <div class="content-wrapper">
          <div v-if="!pending">
            <img src="../assets/img/double-chevron.svg" class="sidebar-chevron" @click="toggleDrawer">
            <h1>{{ getTexts('fieldsListTitle') }}</h1>
            <div id="fields-wrapper" :key="currentTab">
              <div class="fields-list">
                <div v-for="field in getFields" :key="field.id" class="field-checkbox">
                  <field-checkbox
                    :id="`${field.id}`"
                    :var="showFields"
                    :label="getFieldLabel(field.id)"
                    :default="(field.display !== false)"
                  />
                </div>
              </div>
            </div>
            <div class="fields-cta">
              <button class="min-width-button light-grey-button sharp-corner-button" @click="showAllFields()">{{ getButtonLabel('showAllFieldsBtn') }}</button>
              <button class="min-width-button light-grey-button sharp-corner-button" @click="hideAllFields()">{{ getButtonLabel('hideAllFieldsBtn') }}</button>
              <button v-if="showSaveFieldsListBtn" class="min-width-button green-button sharp-corner-button" @click="saveFieldsList()">{{ getButtonLabel('saveFieldsBtn') }}</button>
            </div>
          </div>
          <div v-else style="text-align: center; margin-top: 15px;">
            <i class="loader" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../js/eventBus';
import applicationService from '../mixins/apiServices/application';
import authServices from '../mixins/apiServices/auth';
import fieldCheckbox from './inputFields/fieldCheckbox';

import { useAppStore } from '../store/app';
import { useDomainStore } from '../store/domain';

export default {
  components: {
    'field-checkbox': fieldCheckbox,
  },
  mixins: [applicationService, authServices],
  data () {
    return {
      showDrawer: false,
      showFields: {},
      pending: false,
      appStore: useAppStore(),
      domainStore: useDomainStore(),
    }
  },
  computed: {
    fieldsListText () {
      return this.domainStore.fieldsListText();
    },
    contentText () {
      return this.domainStore.contentText();
    },
    miscButtonLabels () {
      return this.domainStore.miscButtonLabels();
    },
    currentTab () {
      return this.appStore.currentTab;
    },
    currentProduct () {
      return this.appStore.currentProduct;
    },
    fields () {
      return this.appStore.fields;
    },
    getFields () {
      return (this.fields && this.currentProduct && this.currentTab) ? (this.fields[this.currentProduct][this.currentTab]) : {};
    },
    showSaveFieldsListBtn () {
      const { listSettings } = this.appStore;
      if (!listSettings) return true;

      const settings = JSON.parse(listSettings);
      if (!settings[this.currentTab] || !settings[this.currentTab].hideFields) return true;

      const savedHiddenFields = settings[this.currentTab].hideFields;
      const hiddenFields = Object.keys(this.getFields).filter(field => this.getFields[field].display === false);

      const compare = (savedHiddenFields.every(field => hiddenFields.includes(field)) && hiddenFields.every(field => savedHiddenFields.includes(field)));
      return !compare;
    },
  },
  watch: {
    currentTab (newValue, oldValue) {
      if (newValue !== oldValue) this.showDrawer = false;
    }
  },
  mounted () {
    EventBus.$on('toggle-drawer', this.toggleDrawerEvent);
  },
  unmounted () {
    EventBus.$off('toggle-drawer', this.toggleDrawerEvent);
  },
  methods: {
    toggleDrawer () {
      EventBus.$emit('toggle-drawer', { activeDrawer: 'fieldsList' });
      this.showDrawer = !this.showDrawer;
    },
    getTexts (key) {
      if (this.fieldsListText[key]) return this.fieldsListText[key].label || key;
      return key;
    },
    getFieldLabel (fieldID) {
      if (this.contentText[fieldID]) return this.contentText[fieldID].label;
      return fieldID;
    },
    getButtonLabel (index) {
      if (this.miscButtonLabels[index]) return this.miscButtonLabels[index].label;
      return index;
    },
    showAllFields () {
      this.appStore.showAllFields();
    },
    hideAllFields () {
      this.appStore.hideAllFields();
    },
    async saveFieldsList () {
      const hideFields = Object.keys(this.getFields).filter(field => this.getFields[field].display === false);

      this.appStore.setTabHiddenFieldsSettings(this.currentTab, hideFields);
      const { responseCode } = await this.updateUserListSettings({ hideFields, stage: this.currentTab });

      if (responseCode !== 200) {
        throw new Error('Failed to update user fields list settings');
      }
    },

    toggleDrawerEvent ({ activeDrawer }) {
      if (activeDrawer !== 'fieldsList') this.showDrawer = false;
    }
  }
}
</script>

<style scoped>
#fields-wrapper {
  margin-top: 20px;
}

.fields-list {
  color: #000;
  font-weight: 600;
}

.field-checkbox {
  margin-bottom: 20px;
}

.fields-cta {
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: sticky;
  bottom: 0px;
  padding: 20px 0;
  background-color: #f8f8f8;
}

.fields-cta button {
  margin: 0;
}

.fieldslist-drawer {
  width: fit-content;
  height: 100%;
  position: fixed;
  z-index: -1;
  top: 0;
  right: -100%;
  background-color: #f8f8f8;
  color: #716F8B;
  overflow-x: hidden;
  transition: 0.5s;
  margin-top: 60px;
  padding-bottom: 60px;
  border: 1px solid #D8D6E8;
  border-right: none;
  box-shadow: none;
  cursor: default;
}

.button-div {
  margin: 15px 0px;
  position: relative;
  bottom: 0;
}

.button-div > button {
  margin: 0;
}

.showDrawer > .fieldslist-drawer {
  right: 51px;
}

.drawerUnderlay {
  background-color: rgba(0,0,0,0.7);
  height: 100vh;
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  cursor: default;
}

.showDrawer > .drawerUnderlay {
  width: 100%;
}

.fieldslist-drawer > .content-wrapper {
  padding: 10px 15px 0px;
}

.content-wrapper h1 {
  font-size: 20px;
  margin-bottom: 10px;
}

.content-wrapper h2 {
  font-size: 18px;
}

.timestamp {
  margin-left: 5px;
  font-size: 1rem;
}

.drawer-icon {
  padding: 12px;
}

@media only screen and (max-width: 768px) {
  .drawerUnderlay {
    z-index: 1;
  }

  .fieldslist-drawer {
    margin-top: 0;
    padding-bottom: 0px;
    right: -100%;
    z-index: -1;
  }

  .content-wrapper h1 {
    font-size: 18px;
  }

  .showDrawer > .fieldslist-drawer {
    right: 0px;
    z-index: 1;
  }

  .drawer-icon {
    padding: 0px;
  }
}
</style>
