<template>
  <div>
    <img src="../assets/img/graph.svg" class="desktop-only drawer-icon" @click="toggleDrawer">
    <img src="../assets/img/graph.svg" class="mobile-only drawer-icon" style="cursor:pointer" @click="toggleDrawer">
    <div class="drawer-wrapper" :class="(showDrawer) ? 'showDrawer' : ''">
      <div class="drawerUnderlay" @click="showDrawer = false" />
      <div class="performance-drawer">
        <div class="content-wrapper">
          <div v-show="!pending">
            <h1 class="dashboard-title">  <span class="chart-chevron" @click="toggleDrawer" /> {{ getProductName(currentProduct) }} {{ getTexts('pageName') }} </h1>
            <div class="dashboard-container">
              <div class="card disbursement">
                <h2> {{ getTexts('disbursementCardTitle') }} </h2>
                <div class="metric-value" :class="{ 'na-text': formattedLatestMonthlyAmount === 'NA' }">{{ formattedLatestMonthlyAmount }}</div>
                <div class="metric-title-container">
                  <p class="metric-title">{{ getTexts('loans') }} <span class="additional-metric">{{ formattedLatestTotalDisbursed }}</span></p>
                  <p class="metric-title info-container">{{ getTexts('rate') }} <span class="additional-metric" :class="{'na-small-text':formattedLatestDisbursementRate === 'NA'}">{{ formattedLatestDisbursementRate }}</span><performanceTooltip :icon-style="'info'" :icon-position="'bottom'" :body-text=" getTexts('disbursementMetricDefinitionTooltip') " /></p>
                </div>
                <p class="latest-date">{{ formattedOfferAccuracyMonth }}</p>
                <p class="chart-title">{{ getTexts('disbursementChartTitle') }}</p>
                <chart
                  :data="getDisbursementChartData.data"
                  :timeline="getDisbursementChartData.timeline"
                  :tooltip-data="getDisbursementChartData.tooltipData"
                />
                <button class="chart-button" :class="{ active: activeDisbursement === 'monthly-disbursement' }" @click="setDisbursementActive('monthly-disbursement')">{{ getTexts('monthlyButton') }}</button>
                <button class="chart-button" :class="{ active: activeDisbursement === 'weekly-disbursement' }" @click="setDisbursementActive('weekly-disbursement')">{{ getTexts('weeklyButton') }}</button>
                <div class="question-tooltip-box">
                  <performanceTooltip :icon-style="'question'" :icon-position="'bottom-left'" :body-text="getTexts('disbursementEducationTooltip')" />
                </div>
              </div>
              <div class="card response-time">
                <h2 class="info-container"> {{ getTexts('offerResponseCardTitle') }}<performanceTooltip :icon-style="'info'" :icon-position="'bottom'" :body-text="getTexts('offerResponseMetricDefinitionTooltip')" /></h2>
                <span class="metric-value" :class="{'na-text': formattedLatestOfferResponseTime === 'NA'}">{{ formattedLatestOfferResponseTime }}</span><p v-if="formattedLatestOfferResponseTime !== 'NA'" class="inline-text">hrs</p>
                <p class="latest-date">{{ formattedOfferAccuracyMonth }}</p>
                <p class="chart-title">{{ getTexts('offerResponseChartTitle') }}</p>
                <chart
                  :data="getResponseTimeChartData.data"
                  :timeline="getResponseTimeChartData.timeline"
                  :tooltip-data="getResponseTimeChartData.tooltipData"
                />
                <button class="chart-button" :class="{ active: activeResponse === 'monthly-response' }" @click="setResponseActive('monthly-response')">{{ getTexts('monthlyButton') }}</button>
                <button class="chart-button" :class="{ active: activeResponse === 'weekly-response' }" @click="setResponseActive('weekly-response')"> {{ getTexts('weeklyButton') }}</button>
                <p class="industry-average">{{ getTexts('industryAverageLabel') }}: <span class="industry-average-value">{{ getTexts('industryAverageInHours') }}</span></p>
                <div class="question-tooltip-box">
                  <performanceTooltip :icon-style="`question`" :icon-position="'bottom-left'" :body-text="getTexts('offerResponseEducationTooltip')" />
                </div>
              </div>
              <div class="card accuracy">
                <h2 class="info-container">{{ getTexts('offerAccuracyCardTitle') }}<performanceTooltip :icon-style="'info'" :icon-position="'bottom-accuracy'" :body-text="getTexts('offerAccuracyMetricDefinitionTooltip')" /></h2>
                <div class="metric-value" :class="{'na-text': formattedOfferAccuracy === 'NA'}">{{ formattedOfferAccuracy }}<span v-if="formattedOfferAccuracy !== 'NA'">%</span></div>
                <p class="latest-date">{{ formattedOfferAccuracyMonth }}</p>
                <p class="industry-average"> {{ getTexts('industryAverageLabel') }}: <span class="industry-average-value">{{ getTexts('industryAverageInPercentage') }}</span></p>
                <div class="question-tooltip-box">
                  <performanceTooltip class="last-card" :icon-style="`question`" :icon-position="'top'" :body-text="getTexts('offerAccuracyEducationTooltip')" />
                </div>
              </div>
            </div>
          </div>
          <i v-show="pending" class="loader" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import EventBus from '../js/eventBus';
import applicationService from '../mixins/apiServices/application';
import chart from '../components/inputFields/chart';
import performanceTooltip from './performanceTooltip.vue';

import { useAppStore } from '../store/app';
import { useAuthStore } from '../store/auth';
import { useDomainStore } from '../store/domain';
import { useNuxtStore } from '../store/nuxt';

export default {
  components: {
    chart,
    performanceTooltip,
  },
  mixins: [applicationService],
  data () {
    return {
      showDetails: false,
      activeDisbursement: 'monthly-disbursement',
      activeResponse: 'monthly-response',

      showDrawer: false,
      pending: false,
      performanceMetrics: {},
      timestamp: '-',
      monthlyDisbursementData: undefined,
      monthlyOfferResponseTimeData: undefined,
      appStore: useAppStore(),
      authStore: useAuthStore(),
      domainStore: useDomainStore(),
      nuxtStore: useNuxtStore(),
    }
  },
  computed: {
    performanceText () {
      return this.domainStore.performanceText();
    },
    productTexts () {
      return this.domainStore.productTexts();
    },
    stats () {
      return this.performanceMetrics.data.data;
    },
    statsCheck () {
      return this.performanceMetrics?.data?.data;
    },
    getUserFromStore () {
      const { user } = this.authStore;
      return (typeof user !== 'string') ? user: JSON.parse(user);
    },
    // Gets monthly and weekly disbursement chart data depending on the monthly or weekly button selected
    getDisbursementChartData () {
      if (this.activeDisbursement === 'weekly-disbursement') {
        return {
          data: this.formattedWeeklyDisbursementData,
          timeline: this.getWeeklyDisbursedLabels,
          tooltipData: this.weeklyDisbursedTooltipData,
        }
      }
      return {
        data: this.formattedMonthlyDisbursementData,
        timeline: this.getMonthlyDisbursedLabels,
        tooltipData: this.monthlyDisbursedTooltipData,
      }
    },
    // Gets monthly and weekly response time chart data depending on the monthly or weekly button selected
    getResponseTimeChartData () {
      if (this.activeResponse === 'weekly-response') {
        return {
          data: this.formattedWeeklyOfferResponseTime,
          timeline: this.getWeeklyOfferResponseLabels,
          tooltipData: this.weeklyOfferResponseTooltipData,
        }
      }

      return {
        data: this.formattedMonthlyOfferResponseTime,
        timeline: this.getMonthlyOfferResponseLabels,
        tooltipData: this.monthlyOfferResponseTooltipData,
      }
    },

    // TOOLTIP FUNCTIONS
    monthlyDisbursedTooltipData () {
      if (!Array.isArray(this.statsCheck?.disbursementData?.monthly)) {
        return undefined
      }

      const disbursedTooltipData = this.stats.disbursementData.monthly.reduce((previousValue, currentValue, currentIndex) => ({

        ...previousValue,
        [currentIndex]: [
            `${this.getTexts('tooltipRate')}: ${isNaN(currentValue.disbursementRate) ? 'NA' : Math.round(currentValue.disbursementRate * 100)}${!isNaN(currentValue.disbursementRate) ? '%' : ''}`,
            `${this.getTexts('tooltipAmount')}: $${Math.round(currentValue.disbursementAmount).toLocaleString()}`,
            `${this.getTexts('tooltipLoans')}: ${Math.round(currentValue.disbursed)}`,
        ]
      }), {});

      return disbursedTooltipData
    },

    monthlyOfferResponseTooltipData () {
      if (!Array.isArray(this.statsCheck?.offerResponseTime?.monthly)) {
        return undefined
      }
      const disbursedTooltipData = this.stats.offerResponseTime.monthly.reduce((previousValue, currentValue, currentIndex) => ({
        ...previousValue,
        [currentIndex]: [
            `${this.getTexts('tooltipTime')}: ${isNaN(Number(currentValue.ipaTt)) ? 'NA' : Number(currentValue.ipaTt).toFixed(1)} ${!isNaN(currentValue.ipaTt) ? 'hrs' : ''}`
        ]

      }), {});

      return disbursedTooltipData
    },

    weeklyDisbursedTooltipData () {
      if (!Array.isArray(this.statsCheck?.disbursementData?.weekly)) {
        return undefined
      }

      const disbursedTooltipData = this.stats.disbursementData.weekly.reduce((previousValue, currentValue, currentIndex) => ({

        ...previousValue,
        [currentIndex]: [
            `${this.getTexts('tooltipRate')}: ${isNaN(Math.round(currentValue.disbursementRate * 100)) ? 'NA' : Math.round(currentValue.disbursementRate * 100)}${!isNaN(currentValue.disbursementRate) ? '%' : ''}`,
            `${this.getTexts('tooltipAmount')}: $${Math.round(currentValue.disbursementAmount).toLocaleString()}`,
            `${this.getTexts('tooltipLoans')}: ${Math.round(currentValue.disbursed)}`,
            `${moment(currentValue.wkStart).format('l')} - ${moment(currentValue.wkEnd).format('l')}`
        ]

      }), {});

      return disbursedTooltipData
    },

    weeklyOfferResponseTooltipData () {
      if (!Array.isArray(this.statsCheck?.offerResponseTime?.weekly)) {
        return undefined
      }

      const disbursedTooltipData = this.stats.offerResponseTime.weekly.reduce((previousValue, currentValue, currentIndex) => (
        {
          ...previousValue,
          [currentIndex]: [
            `${this.getTexts('tooltipTime')}: ${isNaN(Number(currentValue.ipaTt)) ? 'NA' : Number(currentValue.ipaTt).toFixed(1)} ${!isNaN(currentValue.ipaTt) ? 'hrs' : ''}`,
            `${moment(currentValue.wkStart).format('l')} - ${moment(currentValue.wkEnd).format('l')}`
          ]
        }
      ), {});
      return disbursedTooltipData
    },
    // TOOLTIP FUNCTIONS

    formattedOfferAccuracyMonth () {
      if (!this.statsCheck?.offerResponseTime?.latestMonthlyData?.actionMth) {
        return 'NA';
      }
      return moment(this.stats.offerResponseTime.latestMonthlyData.actionMth).format('MMM YYYY')
    },
    formattedOfferAccuracy () {
      if (!this.statsCheck?.offerAccuracy) {
        return 'NA';
      }
      return `${Math.round(this.stats.offerAccuracy) || 0}`;
    },

    formattedLatestOfferResponseTime () {
      if (!this.statsCheck?.offerResponseTime?.latestMonthlyData?.ipaTt) {
        return 'NA';
      }
      const data = this.stats.offerResponseTime.latestMonthlyData.ipaTt;
      const value = !isNaN(data) ? Number(data).toFixed(1) : 'NA';
      return value;
    },

    formattedLatestDisbursementRate () {
      if (!this.statsCheck?.disbursementData?.latestMonthlyData?.disbursementRate) {
        return 'NA';
      }

      if (!this.statsCheck?.disbursementData?.latestMonthlyData?.disbursed) {
        return 'NA';
      }

      const data = this.stats.disbursementData.latestMonthlyData.disbursementRate;
      const value = !isNaN(data) ? `${Math.round((this.stats.disbursementData.latestMonthlyData.disbursementRate) * 100, 2)}%` : 'NA';
      return value;
    },

    formattedLatestTotalDisbursed () {
      if (!this.statsCheck?.disbursementData?.latestMonthlyData?.disbursed) {
        return '0';
      }

      return Math.round(this.stats.disbursementData.latestMonthlyData.disbursed);
    },

    formattedLatestMonthlyAmount () {
      if (!this.statsCheck?.disbursementData?.latestMonthlyData?.disbursementAmount) {
        return '$0';
      }

      return `$${Math.round(this.stats.disbursementData.latestMonthlyData.disbursementAmount).toLocaleString()}`;
    },
    // MONTHLY CHART FUNCTIONS

    formattedMonthlyDisbursementData () {
      if (!Array.isArray(this.statsCheck?.disbursementData?.monthly)) {
        return [];
      }
      return this.stats.disbursementData.monthly.map(item => item.disbursementRate)
    },
    formattedMonthlyOfferResponseTime () {
      if (!Array.isArray(this.statsCheck?.offerResponseTime?.monthly)) {
        return [];
      }
      const responseTime = this.stats.offerResponseTime.monthly.map(item => item.ipaTt)
      return responseTime;
    },
    getMonthlyDisbursedLabels () {
      if (!Array.isArray(this.statsCheck?.disbursementData?.monthly)) {
        return [];
      }
      return this.stats.disbursementData.monthly.map(item => moment(item.actionMth).format('MMM'));
    },
    getMonthlyOfferResponseLabels () {
      if (!Array.isArray(this.statsCheck?.offerResponseTime?.monthly)) {
        return [];
      }
      return this.stats.offerResponseTime.monthly.map(item => moment(item.actionMth).format('MMM'));
    },
    // MONTHLY CHART FUNCTIONS
    // WEEKLY CHART FUNCTIONS
    formattedWeeklyDisbursementData () {
      if (!Array.isArray(this.statsCheck?.disbursementData?.weekly)) {
        return [];
      }
      return this.stats.disbursementData.weekly.map(item => item.disbursementRate);
    },

    formattedWeeklyOfferResponseTime () {
      if (!Array.isArray(this.statsCheck?.offerResponseTime?.weekly)) {
        return [];
      }
      return this.stats.offerResponseTime.weekly.map(item => item.ipaTt);
    },
    getWeeklyDisbursedLabels () {
      if (!Array.isArray(this.statsCheck?.disbursementData?.weekly)) {
        return [];
      }
      return this.stats.disbursementData.weekly.map(item => item.actionWk.split('-')[1]);
    },
    getWeeklyOfferResponseLabels () {
      if (!Array.isArray(this.statsCheck?.offerResponseTime?.weekly)) {
        return [];
      }
      return this.stats.offerResponseTime.weekly.map(item => item.actionWk.split('-')[1]);
    },
    // WEEKLY CHART FUNCTIONS

    currentProduct () {
      return this.appStore.currentProduct;
    },
    currentTab () {
      return this.appStore.currentTab;
    },
    language () {
      return this.nuxtStore.language;
    },
  },
  watch: {
    // close drawer
    currentProduct (newValue, oldValue) {
      if (newValue !== oldValue) this.showDrawer = false;
    },
    currentTab (newValue, oldValue) {
      if (newValue !== oldValue) this.showDrawer = false;
    }
  },
  mounted () {
    EventBus.$on('toggle-drawer', this.toggleDrawerEvent);
  },
  unmounted () {
    EventBus.$off('toggle-drawer', this.toggleDrawerEvent);
  },
  methods: {
    setDisbursementActive (button) {
      this.activeDisbursement = button;
    },
    setResponseActive (button) {
      this.activeResponse = button;
    },
    getProductName (key) {
      return (this.productTexts && this.productTexts[key]) ? this.productTexts[key] : key;
    },
    getTexts (key) {
      if (this.performanceText[key]) return this.performanceText[key].label || key;
      return key;
    },
    async toggleDrawer () {
      EventBus.$emit('toggle-drawer', { activeDrawer: 'performance' });
      this.showDrawer = !this.showDrawer;
      if (this.showDrawer) {
        this.pending = true;
        this.performanceMetrics = null;
        this.$gtm.push({
          event: 'gtm-open-dashboard',
          userId: this.getUserFromStore?.userId,
          tokenId: this.authStore.token,
          productInternalName: this.appStore.currentProduct,
        })
        await this.getPerformanceStats();
      }
    },
    async getPerformanceStats () {
      const response = await this.retrievePerformanceStats();
      if (response.responseCode !== 200) {
        this.performanceMetrics = null;
        this.pending = false;
        return
      }
      this.performanceMetrics = response;
      this.pending = false;
    },
    toggleDrawerEvent ({ activeDrawer }) {
      if (activeDrawer !== 'performance') this.showDrawer = false;
    }
  }
}
</script>

<style scoped>
p {
  margin: unset;
}

button:not(:first-child) {
  margin-top: unset;
}
/* Existing Code for drawer */
.performance-drawer {
  height: calc(100vh - 60px);
  max-width:  728px;
  width: calc(100vw - 75px);
  margin-left: 40px;
  position: fixed;
  z-index: -1;
  top: 0;
  right: -100%;
  background-color: #EBEBEB;
  overflow-x: hidden;
  transition: 0.5s;
  margin-top: 60px;
  border: 1px solid #D8D6E8;
  border-right: none;
  box-shadow: none;
  cursor: default;
  border-radius: 16px 0 0 16px;
}

.showDrawer > .performance-drawer {
  right: 51px;
}

.drawerUnderlay {
  background-color: rgba(0,0,0,0.7);
  height: 100vh;
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  cursor: default;
}
.drawer-icon {
  padding: 12px;
}

.showDrawer > .drawerUnderlay {
  width: calc(100% - 40px);
}

.performance-drawer > .content-wrapper {
  padding: 32px;
}
.chart-title {
  text-align: center;
}

/* Existing Code for drawer */
/* icons */
.chart-chevron {
  content: url('../assets/img/chart-chevron.svg');
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.question-tooltip-box {
  position: relative;
  top: 6px;
  left: 6px;
}

.question-tooltip.last-card {
  bottom: -5px;
}

.info-container {
  display: flex;
  align-items: center;
  gap: 4px;
}
/* icons */

.dashboard-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 28px 0px 28px 0px;
}

.card {
  background-color: white;
  border-radius: 12px;
  padding: 24px;
}

.dashboard-title {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 120%;
  color: #000000;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}

.latest-date {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
  text-align: left;
  color:#B8B8B8;
  text-transform: capitalize;
  margin: 4px 0 48px 0;
}

.disbursement .latest-date {
  margin-top: 12px;
}
.disbursement .metric-value {
  margin-bottom: 12px;
}

.chart-title {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
  text-align: left;
  color: #707070;
  text-transform: capitalize;
  margin-bottom: 16px;

}

.chart-button {
  width: 76px;
  height: 25px;
  padding: 4px 10px 4px 10px;
  gap: 8px;
  border-radius: 16px;
  background-color:#EBEBEB;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  color: #4D4D4D;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.active {
  background-color: #409044;
  color: #fcfcfc;
}
/* disbursement card v */
.disbursement {
  grid-column: 1 / 2;
  min-width: 320px;
  min-height: 431px;
  top: 80px;
  left: 32px;
  gap: 0px;
  border-radius: 20px;
  color:#FFFFFF;
}

.metric-title-container {
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative
}

.metric-title {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  text-align: left;
  color:#707070;
  text-transform: capitalize;
}

.additional-metric {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  text-align: left;
  color:#1F1F1F;
}
/* disbursement card ^ */

.response-time {
  grid-column: 2 / 3;
  min-width: 320px;
  min-height: 425px;
  top: 81px;
  left: 376px;
  gap: 0px;
  border-radius: 20px;
  color:#FFFFFF;
}
/* accuracy card */
.accuracy {
  grid-column: 2 / 3;
  min-width: 322px;
  min-height: 172px;
  top: 530px;
  left: 376px;
  gap: 0px;
  border-radius: 20px;
  color:#FFFFFF;
}
.industry-average {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
  text-align: left;
  color: #B8B8B8;
  text-transform: capitalize;
  margin-top: 24px;
  position: relative;
  top: 8px;
}
.industry-average-value {
  color: #707070;
}

.response-time .industry-average {
  margin-top: 24px;
}

.accuracy .industry-average {
  margin-top: 16px;
}

.accuracy.card .latest-date {
  margin-bottom: 0px;
}
/* accuracy card */

.card h2 {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 120%;
  text-align: left;
  color:#000000;
  text-transform: capitalize;
  margin-bottom: 16px;
  position: relative;
}

.metric-value {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 120%;
  text-align: left;
  color:#1F1F1F;
}

.na-text {
  color: #B8B8B8;
}

.na-small-text {
  color: #D6D6D6;
}

.inline-text {
  display: inline;
  padding-left: 4px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  text-align: left;
  color:#1F1F1F;
}

.loader {
  margin: 0;
}

@media only screen and (max-width: 1024px) {
  .dashboard-container {
  padding: 28px 0px;
}

}

@media only screen and (max-width: 768px) {
  .dashboard-container {
    grid-template-columns: 1fr;
  }
  .performance-drawer > .content-wrapper {
    padding: 16px;
  }

  .disbursement,
  .response-time,
  .accuracy{
    grid-column: unset;
  }
  .performance-drawer {
    width: 100vw;
    height: 100dvh;
    z-index: 1;
    margin-top: unset;
  }

  .dashboard-container {
    padding: 16px 0 0 0 ;
  }

  .showDrawer > .performance-drawer {
    right: 0px;
  }
}
</style>
