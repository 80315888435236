<template>
  <div>
    <label class="input-container">
      <span> {{ label }} </span>
      <input
        v-model="theModel"
        type="checkbox"
      >
      <span class="checkmark" />
    </label>
  </div>
</template>

<script>
import { useAppStore } from '../../store/app';

export default {
  props: {
    id: String,
    var: Object,
    label: String,
    default: Boolean,
  },

  data () {
    return {
      appStore: useAppStore(),
    }
  },

  computed: {
    theModel: {
      get () {
        const { fields, currentProduct, currentTab } = this.appStore;
        return (fields[currentProduct][currentTab][this.id].display !== false);
      },
      set (val) {
        this.var[this.id] = val;
        this.appStore.toggleField(this.id, val);
      }
    }
  },
  mounted () {
    this.var[this.id] = this.default;
  },
}
</script>

<style scoped>
.input-container {
  display: block;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  margin: 5px 0px 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: fit-content;
}

input {
  display: none;
}

/* custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: white;
  border-radius: 3px;
  border: 1px solid #a7b1b6;
}

/* On mouse-over, add a grey background color */
.input-container:hover input ~ .checkmark {
  background-color: #eee;
}

/* When the checkbox is checked, add a green background */
.input-container input:checked ~ .checkmark {
  background-color: #43B649;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.input-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.input-container .checkmark:after {
  left: 6px;
  top: 1px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.error-div label {
  color: #ff9898;
}

.error-div .checkmark {
  border: 2px solid;
}
</style>
