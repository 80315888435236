<template>
  <div>
    <div :class="iconStyleClassName" @mouseover="showDetails = true" @mouseleave="showDetails = false" />
    <transition name="fade">
      <span v-if="showDetails" class="bubble" :class="iconPlacement">
        {{ bodyText }}
      </span>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    bodyText: String,
    iconStyle: String,
    iconPosition: String,
  },
  data () {
    return {
      showDetails: false,
    }
  },
  computed: {
    iconStyleClassName () {
      switch (this.iconStyle) {
        case 'info':
          return 'info-tooltip';
        case 'question':
          return 'question-tooltip';
        default:
          return '';
      }
    },

    iconPlacement () {
      switch (this.iconPosition) {
        case 'top':
          return 'top';
        case 'bottom':
          return 'bottom';
        case 'bottom-left':
          return 'bottom-left';
        case 'bottom-accuracy':
          return 'bottom-accuracy';
        default:
          return '';
      }
    }
  }
}
</script>

<style scoped>

.question-tooltip {
  content: url('../assets/img/question-tooltip.svg');
  width: 34px;
  height: 34px;
  position: absolute;
  right: -8px;
  bottom: -8px;
  padding: 8px;
  cursor: pointer;
}

.question-tooltip:hover {
  filter: brightness(0.7);
  transition: 0.3s all ease-in-out;
}

.info-tooltip {
  content: url('../assets/img/info-tooltip.svg');
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  padding: 8px;
  position: relative;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

.info-tooltip:hover {
  filter: brightness(0.7);
}

.bubble {
  display: inline-block;
  position: absolute;
  background: #333333;
  min-width: 120px;
  width: fit-content;
  white-space: normal;
  word-wrap: break-word;
  border-radius: 8px;
  padding: 4px 8px;
  color: #FCFCFC;
  z-index: 1;
  box-shadow:rgba(0, 0, 0, 0.1);
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 120%;
  text-transform: initial;
  font-size: 13px;
}

.bottom-left {
  top: 10px;
  right: 0px;
}

.bottom {
  top: 30px;
  left: 0px;
}

.top {
  bottom: 25px;
  right: 0;
}

.bottom-accuracy {
  top: 30px;
  left: 0;
}

div.inner-arrow {
  background: white;
  position: absolute;
  width: 25px;
  height: 25px;
  transform: rotate(45deg);
  top: -10px;
  right: 4%;
  box-shadow: 1px 0px 9px 0px #bdbdbd;
  z-index: -1;
}

@media only screen and (max-width: 768px) {
   .info-tooltip {
    width: 34px;
    height: 34px;
  }

  .question-tooltip {
    width: 36px;
    height: 36px;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
