<template>
  <LineChart :options="chartOptions" :data="chartData" class="chart-container" />
</template>

<script>
import { Line as LineChart } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  components: { LineChart },
  props: {
    data: {
      type: [Array, String],
      default: () => [],
    },
    timeline: {
      type: [Array, String],
      default: () => [],
    },
    tooltipData: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      arrayMetadata: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        fill: false,
        interaction: {
          intersect: false,
          mode: 'index'
        },
        radius: 0,
        clip: false,
        scales: {
          x: {
            grid: {
              display: false
            }
          },
          y: {
            display: false,
            min: 0,
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            displayColors: false,
            bodyFont: {
              size: 13,
              weight: 'bold',
            },
            callbacks: {
              title () {},
              label: (context) => {
                const { dataIndex } = context;

                return this.tooltipData[dataIndex];
              }
            }
          }
        }
      },
    };
  },

  computed: {
    chartData () {
      return {
        labels: this.timeline,
        datasets: [
          {
            label: [],
            data: this.arrayMetadata.map(item => item.formattedValue),
            borderColor: 'rgba(214, 214, 214, 1)',
            borderWidth: 1,
            pointStyle: 'circle',
            pointRadius: 2.5,
            pointHoverRadius: 2.5,
            pointBackgroundColor: this.pointStyle,
            pointBorderColor: 'rgba(184, 184, 184, 1)',
            segment: {
              borderDash: ctx => this.inactiveYAxis(ctx, [6, 6]),
            },
            spanGaps: true,
          }
        ],
      };
    },
  },
  watch: {
    data (val, oldVal) {
      if (val === oldVal) return;

      if (!this.data || !Array.isArray(this.data)) {
        this.arrayMetadata = [];
        return;
      }

      this.arrayMetadata = this.data.map((item) => {
        return {
          value: item,
          formattedValue: isNaN(item) ? 0 : item,
          isNaN: isNaN(item),
        }
      });
    }
  },
  methods: {
    inactiveYAxis (ctx, value) {
      const { p0DataIndex, p1DataIndex } = ctx;
      const { isNaN: p0NaN } = this.arrayMetadata[p0DataIndex];
      const { isNaN: p1NaN } = this.arrayMetadata[p1DataIndex];

      if (
        (p0NaN || p1NaN)
      ) return value;

      return undefined;
    },
    pointStyle () {
      const hollow = 'rgba(255, 255, 255, 1)';
      const filled = 'rgba(184, 184, 184, 1)';
      const pointStyling = [];

      if (!this.arrayMetadata || !Array.isArray(this.arrayMetadata)) return filled;

      this.arrayMetadata.forEach((item) => {
        pointStyling.push(item.isNaN ? hollow : filled);
      })
      return pointStyling;
    },
  }
};
</script>

<style>
.chart-container {
    position: relative;
    border-radius: 20px;
    width: 100%;
    max-height: 120px;
    height: 100%;
    margin-bottom: 20px;
  }
</style>
