import { useAppStore } from "../store/app";

export default {
  data () {
    return {
      appStore: useAppStore(),
    }
  },

  computed: {
    UserViewMixin_appIdentifier () { return this.appStore.userViewSettings?.appIdentifierField || 'partnerPortalId' },

    UserViewMixin_ssi () {
      const { userViewSettings } = this.appStore;
      if (userViewSettings && userViewSettings.ssi !== undefined) {
        return userViewSettings.ssi;
      }
      return true;
    }
  }
}
